export const ADD_SAAS_PLAN_PRICE = {
  operationName: 'addSaasPlanPrice',
  query: `mutation addSaasPlanPrice (
  <KEYS>
  ) {
                addSaasPlanPrice (
                <VALUES>
                ) {
                    _id
                    products {
                        name
                        stripeId
                        prices {
                            name
                            subHeader
                            description
                            price
                            trial
                            paymentPeriod
                            currency
                            stripeId
                            apps {
                                key
                                enabled
                                name
                                items {
                                    name
                                    limit
                                    option
                                    unlimited
                                }
                            }
                        }
                    }
                }
            }`,
};
