import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { queueUpdateProject } from 'store/projects/projectsQueueActions';
import { setProjectName } from 'store/projects/projectsActions';
import Popup from 'UILib/Popup/Popup';
import Tabs from '../../Common/Tabs/Tabs';
import TabTitle from '../../Common/Tabs/Tab/Tab';
import GeneralTab from './GeneralTab/GeneralTab';
import ProductsTab from './ProductsTab/ProductsTab';
import IntegrationsTab from './IntegrationsTab/IntegrationsTab';

import s from './ProjectSettings.module.scss';

const ProjectSettings = (props) => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedFavicon, setUploadedIFavicon] = useState(undefined);
  const [productsInfoList, setProductsInfoList] = useState([
    {
      name: '',
      descriptor: '',
      price: 100,
      currency: 'USD',
    },
  ]); // don't set in useEffect and handleSave

  const [headerScript, setHeaderScript] = useState();
  const [footerScript, setFooterScript] = useState();
  const [customSubDomain, setCustomDomain] = useState();

  useEffect(() => {
    if (props.project) {
      setTitle(props.project.title);
      setDescription(props.project.description);
      setName(props.project.name);
      setHeaderScript(props.project.headerScript);
      setFooterScript(props.project.footerScript);
      setProductsInfoList(props.project.products);
      setCustomDomain(props.project.customSubDomain);
      setUploadedIFavicon(props.project.favicon);
    }
  }, [props.project, props.show]);

  const handleClose = () => {
    props.onClose();
    setUploadedIFavicon(undefined);
  };

  const handleSave = (newName) => {
    props.updateProject(
      {
        name: newName ? newName : name,
        description,
        title,
        headerScript,
        footerScript,
        customSubDomain,
        products: productsInfoList,
        favicon: uploadedFavicon,
      },
      props.project._id
    );
    props.setProjectNameAction(props.project._id, newName ? newName : name);
  };

  if (props.show)
    return (
      <Popup onClose={handleClose}>
        <div className={s.modalBlock}>
          <h1 className={s.modalTitle}>Funnel Settings</h1>
          <div className={s.modalBody}>
            {props.project && (
              <Tabs>
                <TabTitle title="General">
                  <GeneralTab
                    handleSave={handleSave}
                    name={name}
                    setName={setName}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    customSubDomain={customSubDomain}
                    setSubDomain={setCustomDomain}
                    setProjectNameAction={props.setProjectNameAction}
                    onClose={handleClose}
                    id={props.project._id}
                    favicon={uploadedFavicon}
                    onFaviconChange={setUploadedIFavicon}
                  />
                </TabTitle>
                <TabTitle title="Products">
                  <ProductsTab
                    handleSave={handleSave}
                    productsInfoList={productsInfoList}
                    setProductsInfoList={setProductsInfoList}
                    onClose={handleClose}
                  />
                </TabTitle>
                <TabTitle title="Integrations">
                  <IntegrationsTab
                    handleSave={handleSave}
                    headerScript={headerScript}
                    setHeaderScript={setHeaderScript}
                    footerScript={footerScript}
                    setFooterScript={setFooterScript}
                    onClose={handleClose}
                  />
                </TabTitle>
              </Tabs>
            )}
          </div>
        </div>
      </Popup>
    );

  return null;
};

const mapStateToProps = (state) => ({
  loading: state.projects.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (fields, id) => dispatch(queueUpdateProject(fields, id)),
  setProjectNameAction: (id, newName) => dispatch(setProjectName(id, newName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
