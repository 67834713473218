import React, { useEffect, useState, useRef } from 'react';
import { Slider } from 'antd';
import { HfInferenceEndpoint } from '@huggingface/inference';
import {
  AI_COPY_CRAFT_TEST_EMAIL_URL,
  AI_COPY_CRAFT_TEST_SALES_URL,
  AI_COPY_CRAFT_TEST_BRAIN_URL,
} from 'Constants';
import { createSSEConnection, createSSEStream } from 'utils/sse';
import { IAiResponse, ITopic } from 'types';
import { ReactComponent as BackArrowSvg } from 'Assets/icons/backArrow.svg';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import arrowSwg from 'Assets/icons/copyCraft/rightArrow.svg';
import Link from 'UILib/Link/Link';
import CopyCraftChatCard from './CopyCraftChatCard';

import s from './CopyCraft.module.scss';

const types = ['Email Copy', 'Sales Copy']; //, 'Brain'];

interface IProps {
  topicId: string;
}

interface IPrompt {
  role: 'user' | 'assistant';
  content: string;
}

interface IOptions {
  max_new_tokens: number;
  temperature: number;
  repetition_penalty: number;
  return_full_text: boolean;
}

const CopyCraftChat = (props: IProps) => {
  const [topic, setTopic] = useState<ITopic | null>(null);
  const [result, setResult] = useState('');
  const [aIresult, setAiResult] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(false);
  const [type, setType] = useState(types[0]);
  const [responses, setResponses] = useState<IAiResponse[]>([]);
  const [scrollingDown, setScrollingDown] = useState(true);
  const [openOptions, setOpenOptions] = useState(false);

  const [maxTokens, setMaxTokens] = useState(1500);
  const [temperature, setTemperature] = useState(0.7);
  const [penalty, setPenalty] = useState(1.2);
  const [upperTokensRange, setUpperTokenRange] = useState(2000);

  const sseConnection = useRef<HfInferenceEndpoint | null>(null);

  const [
    userScrolledUpDuringGeneration,
    setUserScrolledUpDuringGeneration,
  ] = useState(false);

  useEffect(() => {
    sseConnection.current = createSSEConnection(
      AI_COPY_CRAFT_TEST_EMAIL_URL
    ) as HfInferenceEndpoint;
  }, []);

  useEffect(() => {
    fetchTopic();
    fetchRequests();
  }, [props.topicId]);

  useEffect(() => {
    const chatSection = document.getElementById('chatSection');
    if (chatSection) {
      let lastScrollTop = chatSection.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = chatSection.scrollTop;
        const scrollHeight = chatSection.scrollHeight;
        const clientHeight = chatSection.clientHeight;

        if (currentScrollTop > lastScrollTop) {
          setScrollingDown(true);
        } else if (currentScrollTop < lastScrollTop) {
          setScrollingDown(false);
        }

        if (
          scrollingDown &&
          scrollHeight - currentScrollTop <= clientHeight + 100
        ) {
          setUserScrolledUpDuringGeneration(false);
        } else {
          setUserScrolledUpDuringGeneration(true);
        }

        lastScrollTop = currentScrollTop;
      };

      chatSection.addEventListener('scroll', handleScroll);

      return () => {
        chatSection.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollingDown]);

  const submitInput = async () => {
    if (!sseConnection.current) {
      return;
    }
    setRequestInProgress(true);

    const prompts: IPrompt[] = [];

    responses.forEach((response) => {
      prompts.push({
        role: 'user',
        content: response.request,
      });
      prompts.push({
        role: 'assistant',
        content: response.response,
      });
    });

    prompts.push({
      role: 'user',
      content: inputText,
    });

    const sseOptions: IOptions = {
      max_new_tokens: maxTokens,
      temperature,
      repetition_penalty: penalty,
      return_full_text: false,
    };

    const stream = createSSEStream(sseConnection.current, prompts, sseOptions);

    for await (const r of stream) {
      setAiResult(r.token.text);
    }

    setRequestInProgress(false);
  };

  useEffect(() => {
    setResult(result + aIresult);
  }, [aIresult]);

  useEffect(() => {
    if (!requestInProgress && result) {
      const newResponses = [...responses];
      newResponses.push({
        request: inputText,
        requestType: type,
        response: result,
        hasLiked: 0,
      });
      setResponses(newResponses);
      setInputText('');
      setResult('');
      handleCompleteGeneration(result);
    }
  }, [requestInProgress]);

  const fetchRequests = async () => {
    const newRequests = await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_REQUEST_BY_TOPIC,
      values: {
        topicId: props.topicId,
      },
      headerType: 'USER-AUTH',
    });
    setResponses(newRequests);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 5000) {
      setError(true);
    } else {
      setError(false);
      setInputText(e.target.value);
    }
  };

  const switchServer = (newType: string) => {
    if (newType === type) {
      return;
    }
    if (newType === 'Email Copy') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_EMAIL_URL);
      setUpperTokenRange(2000);
    } else if (newType === 'Sales Copy') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_SALES_URL);
      setUpperTokenRange(4000);
    } else if (newType === 'Brain') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_BRAIN_URL);
      setUpperTokenRange(4000);
    }
    setResponses([]);
    setType(newType);
  };

  const handleDelete = (index: number) => {
    const newResponses = responses.filter((response, i) => i !== index);
    setResponses(newResponses);
  };

  const handleLike = (like: number, index: number) => {
    const newResponses = responses.map((response, i) => {
      if (i === index) {
        if (like === response.hasLiked) {
          response.hasLiked = 0;
        } else {
          response.hasLiked = like;
        }
      }
      return response;
    });
    setResponses(newResponses);
  };

  const fetchTopic = async () => {
    const newTopic = await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_TOPIC_QUERY,
      values: {
        id: props.topicId,
      },
      headerType: 'USER-AUTH',
    });

    setTopic(newTopic);
  };

  const handleCompleteGeneration = async (response: string) => {
    const payload = {
      topicId: props.topicId,
      request: inputText,
      response,
      requestType: type,
    };
    await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_AI_TEXT_REQUEST,
      values: payload,
      headerType: 'USER-AUTH',
    });
  };

  return (
    <div className={s.chatContent}>
      <section className={s.chatSection} id="chatSection">
        <div className={s.headerContainer}>
          <Link
            to="/console/copy-craft"
            className={s.backLink}
            prefixIcon={<BackArrowSvg />}
          >
            Back to projects
          </Link>
          <h2 className={s.header}>Project {topic?.name}</h2>
        </div>
        {responses.map((response, r) => (
          <CopyCraftChatCard
            request={response}
            key={r}
            onLike={(like) => handleLike(like, r)}
            onDelete={() => handleDelete(r)}
            userScrolledUpDuringGeneration={userScrolledUpDuringGeneration}
          />
        ))}
        {requestInProgress && (
          <CopyCraftChatCard
            request={{
              request: inputText,
              requestType: type,
              response: result,
            }}
            userScrolledUpDuringGeneration={userScrolledUpDuringGeneration}
            inProgress={true}
          />
        )}
      </section>

      <section className={s.inputSection}>
        {requestInProgress ? (
          <div className={s.statusBlock}>
            <h2>Status: in progress</h2>
          </div>
        ) : (
          <div className={s.inputContent}>
            <select onChange={(e) => switchServer(e.target.value)}>
              {types.map((type, index) => (
                <option key={index}>{type}</option>
              ))}
            </select>
            <div className={s.inputBlock}>
              <textarea
                value={inputText}
                onChange={handleChange}
                placeholder="Describe your product or goal for this copy"
                className={error ? s.errorTextArea : ''}
              ></textarea>
              {error && (
                <div className={s.errorText}>Maximum characters 5000</div>
              )}

              <button onClick={() => submitInput()} className={s.submitButton}>
                <img src={arrowSwg} />
              </button>
              {/* <button
                onClick={() => setResponses([])}
                className={s.clearButton}
              >
                clear
              </button>
              <button
                onClick={() => setOpenOptions(!openOptions)}
                className={s.optionsButton}
              >
                options
              </button> */}
              {openOptions && (
                <div className={s.options}>
                  <h5>temperature</h5>
                  <Slider
                    value={temperature}
                    onChange={setTemperature}
                    tooltip={{ placement: 'bottom' }}
                    min={0.1}
                    max={2}
                    step={0.1}
                  />
                  <h5>repetition penalty</h5>
                  <Slider
                    value={penalty}
                    onChange={setPenalty}
                    tooltip={{ placement: 'bottom' }}
                    min={0.1}
                    max={2}
                    step={0.1}
                  />
                  <h5>max tokens</h5>
                  <Slider
                    value={maxTokens}
                    onChange={setMaxTokens}
                    tooltip={{ placement: 'bottom' }}
                    min={1000}
                    max={upperTokensRange}
                    step={100}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default CopyCraftChat;
