export const UPDATE_SAAS_PLAN_PRICE = {
  operationName: 'updateSaasPlanPrice',
  query: `mutation updateSaasPlanPrice (
  <KEYS>
  ) {
                  updateSaasPlanPrice (
                  <VALUES>
                  ) {
                      _id
                      products {
                          name
                          stripeId
                          prices {
                              name
                              subHeader
                              description
                              price
                              trial
                              paymentPeriod
                              currency
                              stripeId
                              apps {
                                  key
                                  enabled
                                  name
                                  items {
                                      name
                                      limit
                                      option
                                      unlimited
                                  }
                              }
                          }
  }}
                }`,
};
