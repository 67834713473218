export const UPDATE_GENERATION_TASK_MUTATION = {
  operationName: 'updateGenerationTask',
  query: `mutation updateGenerationTask(
    <KEYS>
          ) {
            updateGenerationTask(
            <VALUES>
            ) {
                _id
                #might need more in a future
              }
            }`,
};
