import Dropdown from 'UILib/Dropdown/Dropdown';
import DropdownLabel from './DropdownLabel/DropdownLabel';
// import CustomArrowsForSizes from './CustomArrows/CustomArrows';
// import FontSelector from 'Components/FontSelector/FontSelector';
import ColorSelectionBox from './ColorSelectionBox/ColorSelectionBox';
// import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
// import { ReactComponent as FontIco } from 'Assets/icons/textEditor/font.svg';

import styles from './SettingsModal.module.scss';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';

// interface ISizeStateType {
//   value: number;
//   step: number;
//   max: number;
//   min: number;
// }

interface IProps {
  styles: IBookleTemplateBlockStyles;
  updateStyles: (actions: IBookleTemplateBlockStyles) => void;
}

const mockOptions = [
  { label: <DropdownLabel value={'400px'} />, value: 400 },
  { label: <DropdownLabel value={'450px'} />, value: 450 },
  { label: <DropdownLabel value={'500px'} />, value: 500 },
  { label: <DropdownLabel value={'550px'} />, value: 550 },
  {
    label: <DropdownLabel value={'600px'} />,
    value: 600,
  },
  { label: <DropdownLabel value={'650px'} />, value: 650 },
  { label: <DropdownLabel value={'700px'} />, value: 700 },
  { label: <DropdownLabel value={'750px'} />, value: 750 },
  { label: <DropdownLabel value={'800px'} />, value: 800 },
];

const SettingsModal = (props: IProps) => {
  // const [currentFontFamily, setCurrentFontFamily] = useState('Inter');

  // const [fontSize, setFontSize] = useState({
  //   value: 14,
  //   step: 1,
  //   max: 100,
  //   min: 2,
  // });

  // const changeFontWeight = (
  //   action: 'increment' | 'decrement',
  //   valueSetter: Dispatch<SetStateAction<ISizeStateType>>
  // ) => {
  //   valueSetter((oldWeight) => {
  //     let currentValue = oldWeight.value;

  //     if (
  //       action === 'increment' &&
  //       currentValue + oldWeight.step <= oldWeight.max
  //     ) {
  //       currentValue += oldWeight.step;
  //     } else if (
  //       action === 'decrement' &&
  //       currentValue - oldWeight.step >= oldWeight.min
  //     ) {
  //       currentValue -= oldWeight.step;
  //     }

  //     return { ...oldWeight, value: currentValue };
  //   });
  // };

  return (
    <div className={styles.container}>
      <div className={styles.settingsSectionContainer}>
        <div className={styles.sectionLabel}>Background:</div>
        <div className={styles.settingsDropdowns}>
          <ColorSelectionBox
            label="Background Color"
            value={props.styles.emailBg || '#fff'}
            onChange={(color) =>
              props.updateStyles({ ...props.styles, emailBg: color })
            }
          />
        </div>
        <div className={styles.sectionLabel}>Body:</div>
        <div className={styles.settingsDropdowns}>
          <Dropdown
            type="stroke"
            label={<DropdownLabel value={'600px'} />}
            onChange={(newValue) =>
              props.updateStyles({ ...props.styles, bodyWidth: newValue })
            }
            value={props.styles.bodyWidth}
            theme="dark"
            options={mockOptions}
          />
          <ColorSelectionBox
            label="Body Background"
            value={props.styles.bodyColor || '#fff'}
            onChange={(color) =>
              props.updateStyles({ ...props.styles, bodyColor: color })
            }
          />
        </div>
      </div>
      {/* <div
        className={`${styles.settingsSectionContainer} ${styles.defaultSettings}`}
      >
        <div className={styles.sectionLabel}>Default Text:</div>
        <div className={styles.settingsDropdowns}>
          <div className={styles.settingsRow}>
            <ColorSelectionBox
              label="Text Color"
              value="#fff"
              onChange={(color) => console.log(color)}
            />
            <ColorSelectionBox
              label="Link Color"
              value="#fff"
              onChange={(color) => console.log(color)}
            />
            <FontSelector
              theme="dark"
              select={currentFontFamily}
              fontIcon={<FontIco />}
              headerClassName={styles.fontSelector}
              onChange={(value) => {
                setCurrentFontFamily(value);
              }}
            />
            <SizeStyleControl
              step={fontSize.step}
              max={fontSize.max}
              min={fontSize.min}
              value={`${fontSize.value}`}
              theme="dark"
              customArrows={
                <CustomArrowsForSizes
                  onIncrement={() => changeFontWeight('increment', setFontSize)}
                  onDecrement={() => changeFontWeight('decrement', setFontSize)}
                />
              }
              postfix={<span>px</span>}
              title="Font Size"
              className={styles.sizeFieldWrapper}
              inputClassName={styles.sizeField}
              onChange={(value) => {
                setFontSize((oldWeight) => ({ ...oldWeight, value: +value }));
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SettingsModal;
