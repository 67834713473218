import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as CartIcon } from 'Assets/icons/cart.svg';
import { ReactComponent as DoneIcon } from 'Assets/icons/doneIcon.svg';
import { ReactComponent as BookIcon } from 'Assets/icons/book-icon.svg';
import { ReactComponent as GroupIcon } from 'Assets/icons/group.svg';
import { ReactComponent as WorldIcon } from 'Assets/images/PageProjectsNavigation/world.svg';
import { ReactComponent as FingerIcon } from 'Assets/icons/finger.svg';
import { ReactComponent as BasketIcon } from 'Assets/icons/basket.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendar.svg';
import { ReactComponent as BasketOrderIcon } from 'Assets/icons/basket-order.svg';
import ChooseBook from 'Components/FunnelBuilder/Forms/Book/ChooseBook';
import ChooseProduct from 'Components/FunnelBuilder/Forms/Book/ChooseProduct';
import ChooseStyleForm from 'Components/FunnelBuilder/Forms/ChooseStyleForm/ChooseStyleForm';
import GeneralInfoForm from 'Components/FunnelBuilder/Forms/GeneralInfoForm/GeneralInfoForm';
import { FunnelCard, FunnelForm, FunnelType } from './types';

export const funnelTypes: FunnelCard[] = [
  {
    id: 'Email',
    color: '#4792d6',
    title: 'Collect Emails',
    subtitle: 'Build a list of potential customers.',
    icon: MailIcon,
  },
  {
    id: 'Book',
    color: '#4957d8',
    title: 'Sell a Book',
    subtitle: 'Market and sell your book directly.',
    icon: BookIcon,
  },
  {
    id: 'Product',
    color: '#25bb73',
    title: 'Sell a Product',
    subtitle: 'Promote and sell your products online.',
    icon: CartIcon,
  },
  {
    id: 'Event',
    color: '#e09531',
    title: 'Host an Event',
    subtitle: 'Organize and manage event registrations.',
    icon: CalendarIcon,
  },
  {
    id: 'Blank',
    color: '#000000',
    title: 'Blank Funnel',
    subtitle: 'Clean canvas for your next creation',
    icon: WorldIcon,
  },
  // {
  //   id: 'HomePage',
  //   color: '#000000',
  //   title: 'Make a Home Page',
  //   subtitle: 'Introduce your company and services.',
  //   icon: WorldIcon,
  // },
];

export const funnelPagesFlows = {
  Book: [
    {
      Icon: MailIcon,
      color: '#4792d6',
      title: 'Lead Page',
      subtitle: 'Collect visitor contacts for updates or offers.',
      aiPageId: '665dfb7f5167da7fbd5b51cc',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'bookDescription',
        STYLE: 'bookStyle',
        TONE: 'bookTone',
        BOOKID: 'bookId',
      },
    },
    {
      Icon: BookIcon,
      color: '#4957d8',
      title: 'Book Sales Page',
      subtitle: 'Showcase and sell your book.',
      aiPageId: '665dfd6b5167da7fbd5b5802',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'bookDescription',
        STYLE: 'bookStyle',
        TONE: 'bookTone',
        BOOKID: 'bookId',
      },
    },
    {
      Icon: CartIcon,
      color: '#faa500',
      title: 'Checkout Page',
      subtitle: 'Secure payment and order confirmation.',
      aiPageId: '665e091072ed541714c9a0fb',
      aiProduct: {
        type: 'STRIPE',
        nameField: 'name',
        currencyField: 'bookCurrency',
        priceField: 'bookPrice',
      },
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'bookDescription',
        STYLE: 'bookStyle',
        TONE: 'bookTone',
        PRICE: 'bookPrice',
        CURRENCY: 'bookCurrency',
        BOOKID: 'bookId',
      },
    },
    {
      Icon: BasketOrderIcon,
      color: '#ff4f4f',
      title: 'Upsell Page',
      subtitle: 'Suggest additional products during purchase.',
      aiPageId: '665e0b8172ed541714c9a7a7',
      aiProduct: {
        type: 'BUTTON',
        idField: 'bookProductId',
        nameField: 'bookProductName',
        currencyField: 'bookProductCurrency',
        priceField: 'bookProductPrice',
        aiField: 'PurchaseButton',
      },
      aiSourceMap: {
        PRODUCT: 'bookProductName',
        DESCRIPTION: 'bookProductDescription',
        STYLE: 'bookStyle',
        TONE: 'bookTone',
        PRICE: 'bookProductPrice',
        CURRENCY: 'bookProductCurrency',
      },
    },
    {
      Icon: DoneIcon,
      color: '#25bb73',
      title: 'Thank You Page',
      subtitle: 'Confirm actions and suggest next steps.',
      aiPageId: '665e0c9472ed541714c9ac0a',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'bookDescription',
        STYLE: 'bookStyle',
        TONE: 'bookTone',
      },
    },
  ],
  Event: [
    {
      Icon: FingerIcon,
      color: '#e09531',
      title: 'Registration Page',
      subtitle: 'Sign up attendees for events.',
      aiPageId: '665f6a04d89ca3e649a64b73',
      aiSourceMap: {
        TRAINING: 'name',
        SOLUTION: 'eventDescription',
        STYLE: 'eventStyle',
        TONE: 'eventTone',
      },
    },
    {
      Icon: DoneIcon,
      color: '#25bb73',
      title: 'Thank You Page',
      subtitle: 'Confirm actions and suggest next steps.',
      aiPageId: '665f69e8d89ca3e649a647ac',
      aiSourceMap: {
        WEBINARNAME: 'name',
        STYLE: 'eventStyle',
        TONE: 'eventTone',
      },
    },
  ],
  HomePage: [
    {
      Icon: WorldIcon,
      color: '#000000',
      title: 'Home Page',
      subtitle: 'Display your company and guide visitors.',
      aiPageId: '665f6db4d89ca3e649a662e9',
      aiSourceMap: {
        name: 'name',
        niche: 'homePageNiche',
        STYLE: 'homePageStyle',
        TONE: 'homePageTone',
      },
    },
  ],
  Blank: [
    {
      Icon: WorldIcon,
      color: '#000000',
      title: 'Blank Page',
      subtitle: 'Blank page for your future content.',
      aiPageId: '66634429d56ae3b7c31e723f',
      aiSourceMap: {
        name: 'name',
      },
    },
  ],
  Email: [
    {
      Icon: GroupIcon,
      color: '#4792d6',
      title: 'Lead / Subscribe Page',
      subtitle: 'Collect visitor contacts for updates or offers.',
      aiPageId: '6657725d83e196016f588a4c',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'mailPageDescription',
        STYLE: 'mailPageStyle',
        TONE: 'mailPageTone',
      },
    },
    {
      Icon: DoneIcon,
      color: '#25bb73',
      title: 'Thank You Page',
      subtitle: 'Confirm actions and suggest next steps.',
      aiPageId: '6657739683e196016f5891a5',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'mailPageDescription',
        STYLE: 'mailPageStyle',
        TONE: 'mailPageTone',
      },
    },
  ],
  Product: [
    {
      Icon: BasketIcon,
      color: '#4957d8',
      title: 'Sales Page',
      subtitle: 'Promote products and encourage buying.',
      aiPageId: '665f75d4d89ca3e649a66e02',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'productDescription',
        STYLE: 'productPageStyle',
        TONE: 'productPageTone',
      },
    },
    {
      Icon: CartIcon,
      color: '#faa500',
      title: 'Checkout Page',
      subtitle: 'Secure payment and order confirmation.',
      aiPageId: '665f78eed89ca3e649a672ff',
      aiProduct: {
        type: 'STRIPE',
        idField: 'productId',
        nameField: 'name',
        currencyField: 'productCurrency',
        priceField: 'productPrice',
      },
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'productDescription',
        STYLE: 'productPageStyle',
        TONE: 'productPageTone',
      },
    },
    {
      Icon: BasketOrderIcon,
      color: '#ff4f4f',
      title: 'Upsell Page',
      subtitle: 'Suggest additional products during purchase.',
      aiPageId: '665f7a66d89ca3e649a67931',
      aiProduct: {
        type: 'BUTTON',
        idField: 'upsellProductId',
        nameField: 'upsellProductName',
        currencyField: 'upsellProductCurrency',
        priceField: 'upsellProductPrice',
        aiField: 'PurchaseButton',
      },
      aiSourceMap: {
        PRODUCT: 'upsellProductName',
        DESCRIPTION: 'upsellProductDescription',
        STYLE: 'productPageStyle',
        TONE: 'productPageTone',
      },
    },
    {
      Icon: DoneIcon,
      color: '#25bb73',
      title: 'Thank You Page',
      subtitle: 'Confirm actions and suggest next steps.',
      aiPageId: '665f7b88d89ca3e649a67d7c',
      aiSourceMap: {
        PRODUCT: 'name',
        DESCRIPTION: 'productDescription',
        STYLE: 'productPageStyle',
        TONE: 'productPageTone',
      },
    },
  ],
};

export const funnelPages: Record<FunnelType, FunnelForm[]> = {
  Book: [
    {
      color: '#4957d8',
      title: 'Choose a Book',
      subtitle: 'Fill the missing fields',
      icon: BookIcon,
      Form: ChooseBook,
      fields: {
        bookTitle: { label: 'Book Title', name: 'name', required: true },
        bookPrice: { label: 'Price', name: 'bookPrice', required: true },
        bookCurrency: {
          label: 'Currency',
          name: 'bookCurrency',
          required: true,
        },
        bookDescription: {
          label: 'Book Description',
          name: 'bookDescription',
          required: true,
        },
        bookId:{
          label: 'Book ID',
          name: 'bookId',
          required: false,
        }
      },
    },
    {
      optional: true,
      color: '#ff4f4f',
      title: 'Product for your Upsell Page',
      subtitle: 'A short description of the product you want to sell',
      icon: BasketOrderIcon,
      Form: ChooseProduct,
      fields: {
        productId: { label: 'productId', name: 'bookProductId' },
        bookProductName: { label: 'Select a product', name: 'bookProductName' },
        bookProductPrice: { label: 'Price', name: 'bookProductPrice' },
        bookProductCurrency: { label: 'Currency', name: 'bookProductCurrency' },
        bookProductDescription: {
          label: 'Product Description',
          name: 'bookProductDescription',
        },
      },
    },
    {
      color: '#59ADCE',
      title: 'Graphics and Tone',
      subtitle: 'Style in which images will be generated',
      icon: BookIcon,
      Form: ChooseStyleForm,
      fields: {
        tone: {
          label: 'Select tone',
          name: 'bookTone',
        },
        style: {
          label: 'Color Scheme',
          name: 'bookStyle',
          required: true,
        },
      },
    },
  ],
  Event: [
    {
      color: '#4957d8',
      title: 'Event Info',
      subtitle: 'Fill the missing fields',
      icon: CalendarIcon,
      Form: GeneralInfoForm,
      fields: {
        input: {
          label: 'Event Name',
          name: 'name',
          required: true,
        },
        textarea: {
          label: 'Event Description',
          name: 'eventDescription',
          required: true,
        },
      },
    },
    {
      color: '#59ADCE',
      title: 'Graphics and Tone',
      subtitle: 'Style in which images will be generated',
      icon: MailIcon,
      Form: ChooseStyleForm,
      fields: {
        tone: {
          label: 'Select tone',
          name: 'eventTone',
        },
        style: {
          label: 'Color Scheme',
          name: 'eventStyle',
          required: true,
        },
      },
    },
  ],
  Blank: [
    {
      color: '#4957d8',
      title: 'Your Blank Page',
      subtitle: 'Fill your new page name',
      icon: WorldIcon,
      Form: GeneralInfoForm,
      fields: {
        input: {
          label: 'Page Name',
          name: 'name',
          required: true,
        },
        // textarea: {
        //   label: 'Page Description',
        //   name: 'homePageNiche',
        //   required: true,
        // },
      },
    },
  ],
  HomePage: [
    {
      color: '#4957d8',
      title: 'Your Home Page',
      subtitle: 'Fill the missing fields',
      icon: WorldIcon,
      Form: GeneralInfoForm,
      fields: {
        input: {
          label: 'Page Name',
          name: 'name',
          required: true,
        },
        textarea: {
          label: 'Page Description',
          name: 'homePageNiche',
          required: true,
        },
      },
    },
    {
      color: '#59ADCE',
      title: 'Graphics and Tone',
      subtitle: 'Style in which images will be generated',
      icon: WorldIcon,
      Form: ChooseStyleForm,
      fields: {
        tone: {
          label: 'Select tone',
          name: 'homePageTone',
        },
        style: {
          label: 'Color Scheme',
          name: 'homePageStyle',
          required: true,
        },
      },
    },
  ],
  Email: [
    {
      color: '#4957d8',
      title: 'What is your promotion?',
      subtitle: 'Fill the missing fields',
      icon: MailIcon,
      Form: GeneralInfoForm,
      fields: {
        input: {
          label: 'Promotion Name',
          name: 'name',
          required: true,
        },
        textarea: {
          label: 'Promotion Description',
          name: 'mailPageDescription',
          required: true,
        },
      },
    },
    {
      color: '#59ADCE',
      title: 'Graphics and Tone',
      subtitle: 'Style in which images will be generated',
      icon: MailIcon,
      Form: ChooseStyleForm,
      fields: {
        tone: {
          label: 'Select tone',
          name: 'mailPageTone',
        },
        style: {
          label: 'Color Scheme',
          name: 'mailPageStyle',
          required: true,
        },
      },
    },
  ],
  Product: [
    {
      color: '#4957d8',
      title: 'Choose a Product',
      subtitle: 'Fill the missing fields',
      icon: BasketOrderIcon,
      Form: ChooseProduct,
      fields: {
        productId: { label: 'productId', name: 'productId' },
        bookProductName: {
          label: 'Product Title',
          name: 'name',
          required: true,
        },
        bookProductPrice: {
          label: 'Price',
          name: 'productPrice',
          required: true,
        },
        bookProductCurrency: {
          label: 'Currency',
          name: 'productCurrency',
          required: true,
        },
        bookProductDescription: {
          label: 'Book Description',
          name: 'productDescription',
          required: true,
        },
      },
    },
    {
      optional: true,
      color: '#ff4f4f',
      title: 'Product for your Upsell Page',
      subtitle: 'A short description of the product you want to sell',
      icon: BasketOrderIcon,
      Form: ChooseProduct,
      fields: {
        productId: { label: 'productId', name: 'upsellProductId' },
        bookProductName: {
          label: 'Select a product',
          name: 'upsellProductName',
        },
        bookProductPrice: { label: 'Price', name: 'upsellProductPrice' },
        bookProductCurrency: {
          label: 'Currency',
          name: 'upsellProductCurrency',
        },
        bookProductDescription: {
          label: 'Product Description',
          name: 'upsellProductDescription',
        },
      },
    },
    {
      color: '#59ADCE',
      title: 'Graphics and Tone',
      subtitle: 'Style in which images will be generated',
      icon: MailIcon,
      Form: ChooseStyleForm,
      fields: {
        tone: {
          label: 'Select tone',
          name: 'productPageTone',
        },
        style: {
          label: 'Color Scheme',
          name: 'productPageStyle',
          required: true,
        },
      },
    },
  ],
};
