import React, { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { FontFamilies } from './FontFamilies';
import { ReactComponent as ArrowUp } from 'Assets/icons/ArrowDown.svg';
import { ReactComponent as Check } from 'Assets/icons/checkMark.svg';
import { ReactComponent as Search } from 'Assets/icons/search.svg';

import styles from './FontSelector.module.scss';

interface ListItem {
  label: string;
  value: string;
}

interface IProps {
  id?: string;
  onChange: (item: string) => void;
  select: string;
  fontIcon?: ReactNode;
  headerClassName?: string;
  theme?: 'light' | 'dark';
  checkIcon?: React.ReactNode;
  arrowUpIcon?: React.ReactNode;
  arrowDownIcon?: React.ReactNode;
}

const FontSelector = (props: IProps) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState<ListItem>();
  const [keyword, setKeyword] = useState<string>('');

  const searchField = useRef(null);
  const isFirstRender = useRef(true);
  const listRef = useRef<HTMLDivElement | null>(null);

  const getOptionsStyles = () =>
    FontFamilies.map((el) => ({
      ...el,
      style: {
        fontFamily: el.label,
      },
    })).sort((a, b) => (a.label > b.label ? 1 : -1));

  const list = getOptionsStyles();

  useEffect(() => {
    if (props.select) {
      selectSingleItem(props.select);
      setTitle(FontFamilies.find((e) => e.value === props.select)?.label || '');
    }
  }, [props.select]);

  useEffect(() => {
    const close = () => {
      setIsListOpen(false);
    };

    if (isListOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }

    return () => {
      window.removeEventListener('click', close);
    };
  }, [isListOpen]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      selectSingleItem(props.select);
    }
  }, [props.select]);

  useEffect(() => {
    if (isListOpen && selectedItem && listRef.current) {
      const selectedElement = listRef.current.querySelector(
        `[data-value="${selectedItem.value}"]`
      ) as HTMLElement;

      if (selectedElement) {
        const container = listRef.current;

        const offsetTop = selectedElement.offsetTop - container.offsetTop;

        container.scrollTo({
          top: offsetTop,
        });
      }
    }
  }, [isListOpen, selectedItem]);

  const selectSingleItem = (value: string) => {
    const selectedItem = list.find((i) => i.value === value);
    if (selectedItem) selectItem(selectedItem, true);
  };

  const selectItem = (item: ListItem, silent: boolean) => {
    const { label } = item;
    const { onChange } = props;

    let foundItem;

    if (!label) {
      foundItem = list.find((i) => i.value === item.value);
    }

    if (label || foundItem) setTitle(foundItem ? foundItem.label : label);
    setIsListOpen(false);
    setSelectedItem(item);

    if (!silent) {
      onChange(item.value);
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
  };

  const toggleList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsListOpen(!isListOpen);
    setKeyword('');
  };

  const filterList = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value.toLowerCase());
  };

  const listItems = () => {
    const { id, checkIcon } = props;
    let tempList = [...list];
    const selectedItemValue = selectedItem?.value;

    if (keyword.length) {
      tempList = list.filter((item) =>
        item.label.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (tempList.length) {
      return tempList.map((item) => (
        <div
          className={clsx(styles.listItem, id)}
          key={item.value}
          data-value={item.value}
          onClick={(e) => {
            e.preventDefault();
            selectItem(item, false);
          }}
          onMouseDown={onMouseDown}
        >
          <span className={styles.listItemCheck}>
            {item.value === selectedItemValue && <>{checkIcon || <Check />}</>}
          </span>
          <img
            className={styles.listItemPreview}
            src={`${process.env.PUBLIC_URL}/assets/fontPreview/${item.value}.svg`}
            alt={item.label}
          ></img>
        </div>
      ));
    }

    return (
      <div className={clsx(styles.listItem, styles.noResult, id)}>
        No results
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.wrapper, styles[props.theme || 'light'], props.id)}
    >
      <Tooltip open={isListOpen ? false : undefined}>
        <div
          className={clsx(
            styles.header,
            styles[props.theme || 'light'],
            props.headerClassName,
            props.id
          )}
          onClick={toggleList}
        >
          <div
            className={clsx(
              styles.headerTitle,
              {
                [styles.headerWithFont]: props.fontIcon,
              },
              props.id
            )}
          >
            {props.fontIcon && props.fontIcon}
            {title}
          </div>
          <span className={isListOpen ? styles.openListArrow : ''}>
            {props.arrowUpIcon || <ArrowUp />}
          </span>
        </div>
      </Tooltip>

      {isListOpen && (
        <div className={clsx(styles.list, styles.searchable, props.id)}>
          <div className={styles.listSearchContainer}>
            <Search />
            <input
              ref={searchField}
              className={clsx(styles.listSearchBar, props.id)}
              placeholder="Search fonts"
              onClick={(e) => e.stopPropagation()}
              onChange={filterList}
            />
          </div>
          <div className={clsx(styles.scrollList, props.id)} ref={listRef}>
            {listItems()}
          </div>
        </div>
      )}
    </div>
  );
};

export default FontSelector;
