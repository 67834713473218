import { ReactElement, useState } from 'react';
import { Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { graphQlCall } from 'graphql/utils';
import { IGenerationTemplate } from 'types';
import { ReactComponent as DeleteIcon } from 'Assets/icons/book/x.svg';
import clsx from 'clsx';
import queries from 'graphql/queries';
import DotsIcon from 'Assets/icons/book/dots.svg';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import PlaceholderIcon from 'Assets/GenerationItemPlaceholder.svg';
import styles from './GenerationTemplateCard.module.scss';

export enum GenerationItemCardType {
  Template,
  Task,
}

export enum GenerationItemCardState {
  Complete = 'COMPLETE',
  Generating = 'GENERATING',
  Error = 'ERROR',
}

interface IProps {
  item: IGenerationTemplate;
  type: GenerationItemCardType;
  state?: GenerationItemCardState;
  onClick?: (senderId: string) => void;
  onDelete: () => void;
  icon?: ReactElement;
}

enum MenuItems {
  Delete,
}

const GenerationTemplateCard = ({
  item,
  onClick,
  onDelete,
  type,
  state,
  icon,
}: IProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const handleMenuOpen = (open: boolean) => {
    setOpenMenu(open);
  };

  const handleDelete = async () => {
    let querryTemplate;
    if (type === GenerationItemCardType.Template) {
      querryTemplate = queries.DELETE_GENERATION_TEMPLATE_MUTATION;
    } else if (type === GenerationItemCardType.Task) {
      querryTemplate = queries.DELETE_GENERATION_TASK_MUTATION;
    }
    if (querryTemplate) {
      const response = await graphQlCall({
        queryTemplateObject: querryTemplate,
        headerType: 'USER-AUTH',
        values: {
          id: item._id,
        },
      });
      if ((response.message = 'Success')) {
        setIsDeletePopupOpen(false);
        onDelete();
      }
    }
  };

  const handleMenuClick = (e: MenuInfo) => {
    setOpenMenu(false);
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.Delete: {
        setIsDeletePopupOpen(true);
        break;
      }
    }
  };

  const menuItems = [
    {
      label: 'Delete',
      key: MenuItems.Delete,
      icon: <DeleteIcon />,
    },
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <>
      <ConfirmArchivationPopup
        open={isDeletePopupOpen}
        description="Are you sure you want to delete this email template? It will no longer be available to you. All data will be lost!"
        onDonePressed={handleDelete}
        onClose={() => setIsDeletePopupOpen(false)}
      />
      <div
        className={clsx(styles.container, { [styles.openedMenu]: openMenu })}
        onClick={() => {
          if (onClick) onClick(item._id);
        }}
      >
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          overlayClassName={'custom'}
          onOpenChange={handleMenuOpen}
        >
          <div
            className={styles.dots}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <img src={DotsIcon} alt="..." />
          </div>
        </Dropdown>
        <div className={styles.thumbnail}>
          <>
            {state && state !== GenerationItemCardState.Complete && (
              <div className={styles.thumbnailLabel}>Generating</div>
            )}
            {item.thumbnail ? (
              <img
                className={styles.thumbnailImage}
                src={item.thumbnail}
                alt={item.name}
              />
            ) : type === GenerationItemCardType.Task ? (
              icon ? (
                <div className={styles.customImage}>{icon}</div>
              ) : (
                <img
                  className={styles.thumbnailImage}
                  src={PlaceholderIcon}
                  alt={item.name}
                />
              )
            ) : (
              <div className={styles.thumbnailPlaceholder}></div>
            )}
          </>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.label}>{item.name}</div>
      </div>
    </>
  );
};

export default GenerationTemplateCard;
