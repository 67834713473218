import { useEffect, useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { connect } from 'react-redux';
import { DispatchType, RootState } from 'store/rootReducer';
import { fetchAutoSassDesign } from 'store/autosaas/autosaasActions';
import { IAgencyDetails } from 'types';
import { fetchAgencyData } from 'store/user/userActions';
import { validateUser } from 'utils/Utils';
import { getDomain } from 'utils/helpers';

import store from './store/rootStore';
import Home from './Pages/PageHome';
import Login from './Pages/PageLogin/PageLogin';
import Signup from './Pages/PageSignup/PageSignup';
import ConfirmEmail from './Pages/PageConfirmEmail';
import Projects from './Pages/PageProjects/PageProjects';
import AlertsBlock from './Components/Alerts';
import SubmitSubscription from './Pages/SubmitSubscription';
import CancelSubscription from './Pages/CancelSubscription';
import Logout from './Pages/PageLogout';
import { AMPLIFY_CLIENT_ID, AMPLIFY_POOL_ID } from './Constants';
import Settings from './Pages/PageSetting/PageSettings';
import PageBooks from './Pages/PageBooks/PageBooks';
import BookBuilder from './Pages/BookBuilder/BookBuilder';
import PageCopyCraft from './Pages/PageCopyCraft/PageCopyCraft';
import ChoosePlan from './Components/Common/ChoosePlan/ChoosePlan';
import AgencyManage from 'Components/Accounts/AgencyManage/AgencyManage';
import CopyCraftTest from './Components/CopyCraft/CopyCraftChat3';
import ConfirmTransfer from './Components/Accounts/ConfirmTransfer/ConfirmTransfer';
import PageFinishSignup from './Pages/PageSignup/PageFinishSignup';
import AutoLogin from './Pages/PageLogin/AutoLogin';
import PageSetPassword from './Pages/PageResetPassword/PageSetPassword';
import PageStartResetPassword from './Pages/PageResetPassword/PageStartResetPassword';
import PageUpdatePaymentForm from './Pages/PageUpdatePaymentForm';
import PageGenerationTemplateEditor from './Pages/PageGenerationEditor/PageGenerationTemplateEditor';
import PageGenerationTaskEditor from './Pages/PageGenerationEditor/PageGenerationTaskEditor';
import PagePodcast from './Pages/PagePodcast/PagePodcast';
import WorkflowBuilder from 'Pages/Workflows/WorkflowBuilder/WorkflowBuilder';
import FunnelBuilder from './Pages/FunnelBuilder/FunnelBuilder';
import PageContacts from './Pages/PageContacts/PageContacts';
import EditPodcastEpisode from './Pages/PagePodcast/EditPodcastEpisode/EditPodcastEpisode';
import BroadcastGenerator from './Pages/Broadcasts/BroadcastGenerator/BroadcastGenerator';
import NewPodcastGenerator from './Pages/PodcastGenerator/NewPodcastGenerator';
import FunnelTemplates from './Pages/FunnelTemplates/FunnelTemplates';
import AddNewAutoFile from './Pages/AddNewAutoFile/AddNewAutoFile';
import Outreach from './Pages/Outreach/Outreach';
import EditBook from './Pages/PageBooks/EditBook/EditBook';
import AutoSaas from './Pages/AutoSaas/AutoSaas';
import NewApp from './Pages/NewApp/NewApp';
import AutoApp from './Pages/AutoApp/AutoApp';

import 'antd/dist/antd.css';
import './App.css';

let awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: AMPLIFY_POOL_ID,
  aws_user_pools_web_client_id: AMPLIFY_CLIENT_ID,
  oauth: {},
};
Amplify.configure(awsconfig);

interface IProps {
  fetchAutoSassDesign: () => void;
  agencyDetails: IAgencyDetails;
  fetchAgencyDetails: () => void;
}

function App({
  fetchAutoSassDesign,
  agencyDetails,
  fetchAgencyDetails,
}: IProps) {
  useEffect(() => {
    fetchAutoSassDesign();
  }, [fetchAutoSassDesign, agencyDetails]);

  useEffect(() => {
    const isValid = validateUser();
    if (isValid) {
      fetchAgencyDetails();
    }
  }, [fetchAgencyDetails]);

  function ConfirmEmailSlug() {
    let { slug } = useParams<{ slug: string }>();
    return <ConfirmEmail confirmationData={slug} />;
  }

  function SignupEmail() {
    let { email } = useParams<{ email: string }>();
    return <Signup email={email} />;
  }

  useLayoutEffect(() => {
    let { domain, isAutoFunnel } = getDomain();

    if (isAutoFunnel) {
      domain = 'app.autofunnel.ai';
    }

    document
      .getElementById('global-styles')
      ?.setAttribute(
        'href',
        `https://cdn.autofunnel.ai/autosaas/${domain}/index.css`
      );
  }, []);

  return (
    <>
      <AlertsBlock alerts={store.getState().alerts.items} />
      <Router>
        <Switch>
          {/* Home */}
          <Route exact={true} path="/console">
            <Home />
          </Route>

          {/* Login */}
          <Route exact={true} path="/console/login">
            <Login />
          </Route>

          {/* Signup */}
          <Route exact={true} path="/console/signup/:email">
            <SignupEmail />
          </Route>
          {/* Reset Password */}
          <Route exact={true} path="/console/reset-password">
            <PageStartResetPassword />
          </Route>

          {/* Confirm Email */}
          <Route exact={true} path="/console/confirm-email/:slug">
            <ConfirmEmailSlug />
          </Route>

          {/* Projects */}

          <Route path={`/console/projects/:slug/page/:pageId`}>
            <Projects />
          </Route>
          <Route path={`/console/projects/:slug/refresh`}>
            <Projects />
          </Route>
          <Route path={`/console/projects/:slug`}>
            <Projects />
          </Route>
          <Route path={`/console/projects`}>
            <Projects />
          </Route>
          <Route path={`/console/app/:app/:appId`}>
            <AutoApp />
          </Route>
          <Route path={`/console/file/:appId/:templateId/create`}>
            <AddNewAutoFile />
          </Route>
          <Route path={`/console/funnel-templates`}>
            <FunnelTemplates />
          </Route>
          <Route path={`/console/purchase`}>
            {/* <Purchase /> */}
            <Redirect to={{ pathname: '/console/login' }} />
          </Route>
          <Route path={`/console/success-subscribed/:session`}>
            <SubmitSubscription />
          </Route>
          <Route path={`/console/cancel-subscribe`}>
            <CancelSubscription />
          </Route>
          <Route path={`/console/logout`}>
            <Logout />
          </Route>
          <Route path={`/console/settings/:option`}>
            <Settings />
          </Route>
          <Route path={`/console/settings`}>
            <Settings />
          </Route>
          <Route path={`/console/books`} exact={true}>
            <PageBooks />
          </Route>
          <Route path="/console/books/:bookId/edit">
            <EditBook />
          </Route>
          <Route
            path={`/console/generationTemplate/:templateId/edit`}
            exact={true}
          >
            <PageGenerationTemplateEditor />
          </Route>
          <Route path={`/console/generationTask/:taskId/edit`} exact={true}>
            <PageGenerationTaskEditor />
          </Route>
          <Route path={`/console/books/create`} exact={true}>
            <BookBuilder />
          </Route>
          <Route path={`/console/copy-craft`} exact={true}>
            <PageCopyCraft />
          </Route>
          <Route path={`/console/copy-craft/:topicId`}>
            <PageCopyCraft />
          </Route>
          <Route path={`/console/podcaster`} exact={true}>
            <PagePodcast />
          </Route>
          <Route path={`/console/podcaster/create`}>
            <NewPodcastGenerator />
          </Route>
          <Route path="/console/podcaster/episode/:episodeId/edit">
            <EditPodcastEpisode />
          </Route>
          <Route path={`/console/podcaster/:podcastId`}>
            <PagePodcast />
          </Route>
          <Route path={`/console/contacts`} exact={true}>
            <PageContacts />
          </Route>
          <Route path={`/console/contacts/:topicId`}>
            <PageContacts />
          </Route>
          <Route path={'/console/agency'}>
            <AgencyManage />
          </Route>
          <Route path={'/console/autosaas'}>
            <AutoSaas />
          </Route>
          <Route path={'/console/confirm-agency-transfer'} exact={true}>
            <ConfirmTransfer />
          </Route>
          <Route path={'/console/test/copy-craft'} exact={true}>
            <CopyCraftTest topicId="" />
          </Route>
          <Route path={'/console/magic-link/:token'} exact={true}>
            <PageFinishSignup />
          </Route>
          <Route path={'/console/autologin'} exact={true}>
            <AutoLogin />
          </Route>
          <Route path={'/console/new-funnel'} exact={true}>
            <FunnelBuilder />
          </Route>
          <Route path={'/console/password-recover/:token'} exact={true}>
            <PageSetPassword />
          </Route>
          <Route path={'/console/frame/update-payment-method'} exact={true}>
            <PageUpdatePaymentForm />
          </Route>
          <Route path={'/console/frame/update-plan'} exact={true}>
            <ChoosePlan />
          </Route>
          <Route path={'/console/outreach'}>
            <Outreach />
          </Route>
          <Route path={`/console/broadcasts/create`} exact={true}>
            <BroadcastGenerator />
          </Route>
          <Route path={`/console/broadcasts/:brodcastId/edit`}>
            <BroadcastGenerator />
          </Route>
          <Route path={`/console/workflow/create`}>
            <WorkflowBuilder />
          </Route>
          <Route path={`/console/new-app`}>
            <NewApp />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  agencyDetails: state.user.agencyDetails,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchAutoSassDesign: () => dispatch(fetchAutoSassDesign()),
  fetchAgencyDetails: () => dispatch(fetchAgencyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
