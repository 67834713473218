export const GET_ONE_AUTO_APP = {
  operationName: 'getOneAutoApp',
  query: `query getOneAutoApp ($id: String!) {
              getOneAutoApp (id: $id) {
                _id 
                template { 
                  _id
                  layout
                }
                name
                description
                iconUrl
                createdAt
                updatedAt
              }
          }`,
};
