import { useEffect, useState } from 'react';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { AutoSassIconsOptions } from 'Components/SideMenu/constants';
import { ReactComponent as TemplatesIcon } from 'Assets/icons/appIcons/templates.svg';
import { applications } from 'Components/AutoSaas/constants';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import Dropdown from 'UILib/Dropdown/Dropdown';

import styles from './Applications.module.scss';

interface IProps {
  data: any;
  setData: (item: any) => void;
  autoApps?: IAutoApp[];
}

const Applications = ({ data, setData, autoApps }: IProps) => {
  const [apps, setApps] = useState<{ [key: string]: any }[]>(applications);

  useEffect(() => {
    if (autoApps?.length) {
      const newApps = [
        ...applications,
        ...autoApps.map((e) => ({
          key: e.name,
          name: e.name,
          icon: AutoSassIconsOptions.find((i) => i.path === e?.iconUrl)
            ?.icon || <TemplatesIcon />,
          selects: [],
        })),
      ];

      setApps(newApps);
    }
  }, [autoApps]);

  const onEnableItem = (key: string, enabled: boolean) => {
    const applicationExists = data?.apps?.some((e: any) => e?.key === key);

    setData({
      ...data,
      apps: applicationExists
        ? data?.apps.map((app: any) =>
            app.key === key ? { ...app, enabled } : app
          )
        : [
            ...(data?.apps || []),
            {
              key,
              name: key?.toLocaleLowerCase(),
              enabled,
              items: [],
            },
          ],
    });
  };

  const onChangeName = (key: string, name: string) => {
    const applicationExists = data?.apps?.some((e: any) => e?.key === key);
    setData({
      ...data,
      apps: applicationExists
        ? data?.apps.map((app: any) =>
            app.key === key ? { ...app, name } : app
          )
        : [
            ...(data?.apps || []),
            {
              key,
              name,
              enabled: false,
              items: [],
            },
          ],
    });
  };

  const onSelect = (name: string, value: string, applicationKey: string) => {
    const applicationExists = data?.apps?.some(
      (e: any) => e?.key === applicationKey
    );

    setData({
      ...data,
      apps: applicationExists
        ? data?.apps.map((app: any) => {
            return app.key === applicationKey
              ? {
                  ...app,
                  items: app.items?.some((item: any) => item.name === name)
                    ? app.items.map((item: any) =>
                        item.name === name ? { ...item, option: value } : item
                      )
                    : [...(app.items || []), { name, option: value }],
                }
              : app;
          })
        : [
            ...(data?.apps || []),
            {
              key: applicationKey,
              name: applicationKey?.toLocaleLowerCase(),
              enabled: false,
              items: [{ name, option: value }],
            },
          ],
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Applications</div>
      <div className={styles.applications}>
        {apps?.map((application: any) => {
          const applicationData = data?.apps?.find(
            (e: any) => e?.key === application?.key
          );
          return (
            <div className={styles.application} key={application.key}>
              <div className={styles.iconContainer}>
                <div className={styles.name}>
                  {application.icon}
                  <div className={styles.appName}>
                    {applicationData?.name || applicationData?.name === ''
                      ? applicationData?.name || ''
                      : application.key?.toLocaleLowerCase()}
                  </div>
                </div>
                <ToggleSwitch
                  isBinary
                  checkValue={applicationData?.enabled}
                  onToggle={(enabled) => onEnableItem(application.key, enabled)}
                />
              </div>
              <div className={styles.dropdownsContainer}>
                {application.selects.map((select: any) => {
                  const item = applicationData?.items?.find(
                    (e: any) => e.name === select.key
                  );

                  return (
                    <Dropdown
                      key={select.key}
                      options={select.options}
                      onChange={(value) =>
                        onSelect(select?.key, value, application.key)
                      }
                      label={select.label}
                      type="stroke"
                      value={item?.option}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Applications;
