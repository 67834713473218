import clsx from 'clsx';
import { createPortal } from 'react-dom';
import {
  useRef,
  useMemo,
  useState,
  ReactNode,
  useCallback,
  HTMLAttributes,
  useLayoutEffect,
  DetailedHTMLProps,
} from 'react';
import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMark.svg';

import styles from './Dropdown.module.scss';

interface IProps<T>
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange'
  > {
  value?: T;
  label: string | ReactNode;
  type?: 'stroke' | 'one-line';
  theme?: 'light' | 'dark';
  size?: 'large' | 'medium' | 'small';
  options: { label: ReactNode; value: T }[];
  onChange: (newValue: T) => void;
  hasErrors?: boolean;
  disabled?: boolean;
  optionsClassName?: string;
}

const Dropdown = <T extends ReactNode>({
  hasErrors,
  className,
  onChange,
  options,
  size = 'large',
  theme = 'light',
  label,
  value,
  type = 'one-line',
  disabled,
  optionsClassName,
  ...rest
}: IProps<T>) => {
  const [open, setOpen] = useState(false);
  const [openDirection, setOpenDirection] = useState<'down' | 'up'>('down');
  const [optionsPosition, setOptionsPosition] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });
  const [positionLocked, setPositionLocked] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLUListElement>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setOpen((prev) => {
        if (!prev) {
          setPositionLocked(false);
        }
        return !prev;
      });
    }
  };

  const handlePosition = useCallback(() => {
    if (positionLocked) return;

    const dropdownRect = containerRef.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const optionHeight = size === 'large' ? 50 : size === 'medium' ? 40 : 30;
    const dropdownMenuHeight = Math.min(options.length * optionHeight, 300);

    if (dropdownRect) {
      const spaceBelow = windowHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;

      if (spaceBelow < dropdownMenuHeight && spaceAbove > dropdownMenuHeight) {
        setOpenDirection('up');
      } else {
        setOpenDirection('down');
      }

      setOptionsPosition({
        top: dropdownRect.top,
        left: dropdownRect.left,
        bottom: dropdownRect.bottom,
        right: dropdownRect.right,
      });
    }
  }, [options, size, positionLocked]);

  useLayoutEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        optionsContainerRef.current &&
        optionsContainerRef.current.contains(e.target as Node)
      ) {
        e.stopPropagation();
        return;
      }
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setOpen(false);
        setPositionLocked(false);
      }
    };

    const handleResize = () => {
      setOpen(false);
    };

    if (open && !positionLocked) {
      handlePosition();
      setPositionLocked(true);
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handlePosition);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      window.removeEventListener('wheel', handlePosition);
      window.removeEventListener('resize', handleResize);
    };
  }, [handlePosition, open, positionLocked]);

  const handleSelect = (option: T) => {
    setOpen(false);
    setPositionLocked(false);
    onChange(option);
  };

  const selectedOptionLabel = useMemo(() => {
    const option = options.find((opt) => opt.value === value);
    if (option) return option.label;

    return value;
  }, [value, options]);

  return (
    <div
      ref={containerRef}
      className={clsx(styles.dropdown, className)}
      {...rest}
    >
      <span
        className={clsx(
          styles.selected,
          { [styles.openState]: open },
          { [styles.error]: hasErrors },
          styles[`border-${type}`],
          styles[theme],
          styles[size]
        )}
        onClick={toggleDropdown}
      >
        {selectedOptionLabel ? (
          <span className={styles.value}>{selectedOptionLabel}</span>
        ) : (
          <span className={styles.label}>{label}</span>
        )}{' '}
        <Arrow className={styles.openIcon} />
      </span>
      {createPortal(
        <ul
          ref={optionsContainerRef}
          className={clsx(
            styles.optionsList,
            styles[theme],
            styles[size],
            {
              [styles.open]: open,
            },
            optionsClassName
          )}
          style={{
            width: containerRef.current?.getBoundingClientRect().width,
            top:
              openDirection === 'down'
                ? optionsPosition.top +
                  (containerRef.current?.getBoundingClientRect().height || 0)
                : 'auto',
            left: optionsPosition.left,
            bottom:
              openDirection === 'up'
                ? window.innerHeight -
                  optionsPosition.bottom +
                  (containerRef.current?.getBoundingClientRect().height || 0)
                : 'auto',
          }}
        >
          {options.map((option, index) =>
            option.label !== '<split>' ? (
              <li
                key={index}
                onClick={() => handleSelect(option.value)}
                className={styles.dropdownOption}
              >
                {option.value === value && (
                  <CheckMarkIcon className={styles.checkMarkIcon} />
                )}
                {option.label}
              </li>
            ) : (
              <div className={styles.optionSplit} key={`split-${index}`}></div>
            )
          )}
        </ul>,
        document.body
      )}
    </div>
  );
};

export default Dropdown;
