import clsx from 'clsx';
import Input from 'UILib/Input/Input';
import TextArea from 'UILib/TextArea/TextArea';
import Dropdown from 'UILib/Dropdown/Dropdown';

import styles from './PlanDetails.module.scss';

interface IProps {
  data: any;
  setData: (item: any) => void;
  isUpdating: boolean;
}

const NAME_LENGTH = 20;
const SUBHEADER_LENGTH = 50;
const DESCRIPTION_LENGTH = 250;

const trialOptions = [
  {
    value: '0',
    label: 'No Trial',
  },
  {
    value: '7',
    label: '7 Days',
  },
  {
    value: '14',
    label: '14 Days',
  },
  {
    value: '30',
    label: '30 Days',
  },
];

const planOptions = [
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'annual',
    label: 'Annual',
  },
];

const PlanDetails = ({ data, setData, isUpdating }: IProps) => {
  const updateData = (key: string, value: string | number) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>About Plan</div>
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            Plan Name <span className={styles.asterisk}>*</span>
          </div>
          <div className={styles.limit}>
            {data?.name?.length || 0}/{NAME_LENGTH}
          </div>
        </div>
        <Input
          placeholder="Name your product"
          border="stroke"
          className={styles.input}
          maxLength={NAME_LENGTH}
          onChange={(e) =>
            e.target.value.length <= NAME_LENGTH &&
            updateData('name', e.target.value)
          }
          value={data?.name}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>Description</div>
          <div className={styles.limit}>
            Characters: {data?.description?.length || 0}/{DESCRIPTION_LENGTH}
          </div>
        </div>
        <TextArea
          placeholder="Describe your product"
          onChange={(e) =>
            e.length <= DESCRIPTION_LENGTH && updateData('description', e)
          }
          maxLength={DESCRIPTION_LENGTH}
          value={data?.description}
        />
      </div>
      <div className={styles.paymentPlanContainer}>
        <div className={styles.title}>Payment Plan</div>
        <div className={styles.planInputs}>
          <div>
            <div className={styles.label}>
              Subscription <span className={styles.asterisk}>*</span>
            </div>
            <Dropdown
              options={planOptions}
              value={data?.paymentPeriod}
              label="Select an option"
              onChange={(e) => updateData('paymentPeriod', e)}
              type="stroke"
              className={styles.priceInput}
              disabled={isUpdating}
            />
          </div>
          <div>
            <div className={styles.label}>
              Price <span className={styles.asterisk}>*</span>
            </div>
            <Input
              type="number"
              hideArrows
              border="stroke"
              className={clsx(styles.input, styles.priceInput)}
              postfixIcon={<div className={styles.usd}>USD</div>}
              onChange={(e) =>
                updateData('price', parseInt(e.target.value) * 100)
              }
              value={data?.price / 100} //* NOTE Price we are getting from backend is in cents so we need to convert in to dollars
              disabled={isUpdating}
            />
          </div>
          <div>
            <div className={styles.label}>
              Trial <span className={styles.asterisk}>*</span>
            </div>
            <Dropdown
              options={trialOptions}
              value={data?.trial !== undefined ? String(data?.trial): undefined}
              label="Select an option"
              onChange={(e) => updateData('trial', e)}
              type="stroke"
              className={styles.priceInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
