import clsx from 'clsx';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { createSocket, getToken } from 'utils/Utils';
import { RootState } from 'store/rootReducer';
import { Descendant } from 'slate';
import { unified } from 'unified';
import markdown from 'remark-parse';
import gfm from 'remark-gfm';
import frontmatter from 'remark-frontmatter';
import { remarkToSlate } from 'remark-slate-transformer';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { Sidebar, MenuItems } from '../Draggable/Sidebar/Sidebar';
import Button from 'UILib/Button/Button';
import Content from '../Draggable/Content/Content';
import SettingsModal from './SettingsModal/SettingsModal';

import styles from './GenerationPreview.module.scss';
import Loader from 'UILib/Loader/Loader';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  isSidebarHidden?: boolean; // this attribute will hide the sidebar toggle
  onSidebarToggle?: (isSidebarOpen: boolean) => void; // even't which should be handled once toggle sidebar
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;

  generationTaskId?: string; //subscribing on reciving data for generation;
}

const GenerationPreview = (props: IProps) => {
  const socket = useRef<Socket | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const [generationTask, setGenerationTask] = useState<any>({});
  const [isGenerating, setIsGenerating] = useState(false);

  const settingsModalRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  type SocketCallback = (payload: any) => void;

  const setupSocketListeners = (
    socket: Socket | null,
    event: string,
    callback: SocketCallback
  ): (() => void) => {
    if (socket) {
      socket.on(event, callback);
      return () => socket.off(event, callback);
    }
    return () => {};
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();

      if (socket.current) {
        socket.current.on('connect', () => {
          console.log('connected to server');
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log('***UPDATING BLOCKS: ', props.templateBlocks);
  // }, [props.templateBlocks]);

  useEffect(() => {
    if (isGenerating) {
      const cleanup = setupSocketListeners(
        socket.current,
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );

      return cleanup;
    }
    //TODO: potential area for optimization. we no need to trigger this one on evert props.templateBlocks update
  }, [isGenerating, props.templateBlocks]);

  useEffect(() => {
    if (props.generationTaskId) {
      loadGenerationTaskData(props.generationTaskId);
    }
  }, [props.generationTaskId]);

  useEffect(() => {
    if (generationTask) {
      let blocks = generationTask.layout;

      if (generationTask.status === 'GENERATING') {
        setIsGenerating(true);

        subscribeOnGenerationTaskUpdates(generationTask._id);
        //mark all nodes that have variable as pennding for generation
        for (const block of blocks) {
          if (block.variable) {
            block.generating = true;
          }
        }
      }
      if (
        generationTask.status === 'COMPLETE' ||
        generationTask.status === 'GENERATING'
      ) {
        //handling application of already generated data to template
        for (const key in generationTask.data) {
          const value = generationTask.data[key];
          blocks = updateGenerationBlockContent(key, value.value, blocks);
        }
      }
      if (blocks && blocks.length > 0) {
        props.updateBlocks(blocks);
      }
    }
  }, [generationTask]);

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    console.log('incoming:', payload);
    if (payload.action === 'content generated') {
      if (payload.data) {
        //TODO: need to fix backend and send error outside data
        const pathComponents = payload.path.split('.');
        const variable = pathComponents[pathComponents.length - 1];
        let updatedBlocks;
        if (payload.data.error) {
          updatedBlocks = updateGenerationBlockContent(
            variable,
            { error: payload.data.error },
            props.templateBlocks
          );
          console.log('content generation error: ', payload.data.error);
        } else {
          updatedBlocks = updateGenerationBlockContent(
            variable,
            payload.data.result,
            props.templateBlocks
          );
        }

        props.updateBlocks([...updatedBlocks]);
      }
    } else if (payload.action === 'task is complete') {
      setIsGenerating(false);
    }
  };

  const loadGenerationTaskData = (taskId: string) => {
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TASK,
      values: { id: taskId },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTask(data);
      })
      .catch((err) => console.log(err));
  };

  const convertNode = (node: any) => {
    switch (node.type) {
      case 'root':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'paragraph':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'text':
        return {
          text: node.value,
        };
      case 'heading':
        return {
          type: 'title',
          // type: `heading-${node.depth}`,
          depth: node.depth,
          children: node.children.map(convertNode),
        };
      // case "emphasis":
      //   return {
      //     type: "emphasis",
      //     children: node.children.map(convertNode),
      //   };
      case 'break':
        return {
          type: 'break',
          children: [{ text: '\n' }],
        };
      case 'thematicBreak':
        return {
          type: 'line',
          children: [{ text: '' }],
        };
      case 'list':
        return {
          type: 'bulleted-list',
          // ordered: node.ordered,
          children: node.children.map(convertNode),
        };
      case 'listItem':
        return {
          type: 'list-item',
          children: node.children.map(convertNode),
        };
      // case "link":
      //   return {
      //     type: "link",
      //     url: node.url,
      //     children: node.children.map(convertNode),
      //   };
      default:
        const finalNode = node;
        if (node.strong) {
          finalNode.bold = true;
        }
        return finalNode;
    }
  };

  const parseMarkdown = (content: string) => {
    const toSlateProcessor = unified()
      .use(markdown)
      .use(gfm)
      .use(frontmatter)
      .use(remarkToSlate);
    const toSlate = (s: string) => toSlateProcessor.processSync(s).result;
    return toSlate(content).map(convertNode);
  };

  const anyBlockInGeneration = (blocks: any) => {
    for (const block of blocks) {
      if (block.generating) {
        return true;
      }
    }
    return false;
  };

  const updateGenerationBlockContent = (
    variable: string,
    content: any,
    blocks: any[]
  ) => {
    for (const block of blocks) {
      if (block.variable === variable) {
        block.generating = false;

        if (anyBlockInGeneration(blocks) === false) {
          setIsGenerating(false);
        }

        if (content.error) {
          block.error = content.error.replace('400', ''); //TODO: remove 400 from OpenAI original message
        } else {
          block.error = undefined;
          if (block.type === MenuItems.TEXT_BLOCK) {
            block.text = parseMarkdown(content) as Descendant[];
          } else if (block.type === MenuItems.IMAGE_BLOCK) {
            block.image = content;
          }
        }
        return blocks;
      }
    }
    return blocks;
  };

  /////////////////////////////////////////////

  UseOnClickOutside(settingsModalRef, (event) => {
    const targetElement = event.target as HTMLElement;
    if (
      isSettingsModalOpen &&
      !Array.from(targetElement.classList).some((className) =>
        className.includes('Dropdown')
      )
    ) {
      handleCloseSettings(event as MouseEvent);
    }
  });

  UseOnClickOutside(sidebarRef, () => {
    if (isSidebarOpen) toggleSidebar();
  });

  useEffect(() => {
    if (props.isSidebarHidden) {
      setIsSidebarOpen(false);
    }
  }, [props.isSidebarHidden]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (props.onSidebarToggle) props.onSidebarToggle(!isSidebarOpen);
  };

  const handleCloseSettings = (event: MouseEvent) => {
    if (
      settingsModalRef.current &&
      !settingsModalRef.current.contains(event.target as Node)
    ) {
      setIsSettingsModalOpen(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Button
        className={clsx(styles.addNewFormButton, styles.openSidebarButton, {
          [styles.hiddenSidebar]: props.isSidebarHidden,
        })}
        prefixIcon={<PlusIcon />}
        appearance="solid"
        width={40}
        height={40}
        onClick={toggleSidebar}
      />
      {isSettingsModalOpen ? (
        <div ref={settingsModalRef}>
          <SettingsModal
            styles={props.templateBlockStyles}
            updateStyles={props.updateBlockStyles}
          />
        </div>
      ) : (
        <>
          <Button
            className={clsx(styles.addNewFormButton, styles.settingsButton)}
            prefixIcon={<SettingsIcon className={styles.settingsIcon} />}
            appearance="solid"
            width={40}
            height={40}
            onClick={() => {
              setIsSettingsModalOpen(!isSettingsModalOpen);
            }}
          />
        </>
      )}
      <div className={styles.container}>
        {isGenerating ? (
          <div className={styles.loaderContainer}>
            <Loader color="#d0d0d0" />
            GENERATING...
          </div>
        ) : (
          <>
            <div
              ref={sidebarRef}
              className={clsx(styles.sidebar, {
                [styles.closed]: !isSidebarOpen,
              })}
            >
              <Sidebar />
            </div>
            <Content />
          </>
        )}
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerationPreview);
