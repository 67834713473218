import clsx from 'clsx';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { ReactComponent as FolderIcon } from 'Assets/icons/folder.svg';
import { BookleTemplateBlock } from 'types';
import { uploadFile } from 'utils/helpers';
import { findItemById, updateItemStyle } from '../utils';

import styles from './ImageBlock.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlocks: BookleTemplateBlock[];
}

const ImageBlock = ({
  item,
  updateBlocks,
  templateBlocks,
}: IProps): JSX.Element => {
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleUploadImage = (file: Blob, image: string) => {
    const newArray = [...templateBlocks];
    const imageItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );

    uploadFile(file, (value) => {
      imageItem.image = value as string;

      updateBlocks(newArray);
    });

    if (image) {
      imageItem.image = image;
      updateBlocks(newArray);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();

    const items = e.dataTransfer.items;

    if (items && items.length > 0) {
      const item = items[0];
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        setIsDragging(true);
      }
    }
  };

  const handleDragLeave = () => {
    !!isDragging && setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0] && files[0].type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        event.target &&
          handleUploadImage(files[0], event.target.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        event.target && handleUploadImage(file, event.target.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight } = e.target as HTMLImageElement;
    const containerHeight = containerRef.current?.offsetHeight || 0;

    if (!item.customHeight) {
      updateItemStyle(
        'height',
        containerHeight && naturalHeight > containerHeight
          ? containerHeight
          : naturalHeight,
        templateBlocks,
        updateBlocks,
        item
      );
    }
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.emptyImageContainer]: !item.image,
      })}
      style={{
        ...(item.hasColor ? { backgroundColor: item.color } : {}),
        justifyContent: item.alignment as any,
        ...(item.hasSpacing
          ? {
              paddingBottom: item.styles?.blockSpacing?.paddingBottom,
              paddingTop: item.styles?.blockSpacing?.paddingTop,
              paddingLeft: item.styles?.blockSpacing?.paddingLeft,
              paddingRight: item.styles?.blockSpacing?.paddingRight,
            }
          : {}),
      }}
    >
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={clsx(styles.imageContent, {
          [styles.emptyImageContent]: !item.image,
          [styles.activeImageContent]: isDragging,
        })}
      >
        {!item.image && !item.imagePreview ? (
          <div className={styles.emptyImage}>
            {item.error ? (
              <div className={styles.emptyText}>{item.error}</div>
            ) : (
              <>
                <div className={styles.emptyText}>
                  Drag and drop cover image, click to browse or generate with AI
                </div>
                <label htmlFor={`image-${item.id}`}>
                  <div className={styles.browseButton}>
                    <FolderIcon /> Browse
                  </div>
                </label>
              </>
            )}
          </div>
        ) : (
          <div className={styles.image} ref={containerRef}>
            <img
              src={item.imagePreview || item.image}
              alt=""
              crossOrigin="anonymous"
              style={item.customHeight ? { height: item.styles?.height } : {}}
              onLoad={handleImageLoad}
            />
          </div>
        )}
        {item.generating && <div className={styles.generating}></div>}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        id={`image-${item.id}`}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageBlock);
