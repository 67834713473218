import { Dropdown } from 'antd';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/copyCraft/duplicate.svg';
import { ReactComponent as ProductIcon } from 'Assets/icons/product-icon.svg';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { IUserProduct } from 'types';
import Button from 'UILib/Button/Button';

import styles from './ProductCard.module.scss';

interface IProps {
  product: IUserProduct;
  onClick: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

const ProductCard = ({ product, onClick, onDelete, onDuplicate }: IProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.productData}>
        <ProductIcon />
        <div className={styles.info}>
          <div className={styles.firstRow}>
            {product.name}
            {!!product.price && (
              <div className={styles.price}>${product.price}</div>
            )}
          </div>
          <div className={styles.description}>{product.description}</div>
        </div>
      </div>
      <Dropdown
        menu={{
          items: [
            {
              key: 'duplicate',
              icon: <DuplicateIcon fill="#000000" />,
              onClick: (e) => {
                e.domEvent.stopPropagation();
                onDuplicate();
              },
              label: <div>Duplicate</div>,
            },
            {
              key: 'delete',
              icon: <RemoveIcon fill="#000000" />,
              onClick: (e) => {
                e.domEvent.stopPropagation();
                onDelete();
              },
              label: <div>Delete</div>,
            },
          ],
        }}
        trigger={['click']}
        overlayClassName={'custom'}
      >
        <Button
          appearance="stroke"
          width={40}
          height={40}
          prefixIcon={<MoreIcon fill="black" />}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    </div>
  );
};

export default ProductCard;
