import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import {
  findItemById,
  updateItem,
  updateItemStyle,
} from 'Pages/PageGenerationEditor/Draggable/utils';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as ChangeHeight } from 'Assets/icons/changeHeight.svg';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { uploadFile } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import CustomArrowsForSizes from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/CustomArrows/CustomArrows';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import UrlInput from '../../UrlInput/UrlInput';

import styles from './ImageTab.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const ImageTab = ({ item, templateBlocks, updateBlocks }: IProps) => {
  const onUpdateItem = (key: string, value: string | boolean) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  const onUpdateStyle = (key: string, value: string | number) => {
    updateItemStyle(key, value, templateBlocks, updateBlocks, item);
  };

  const changeSize = (
    action: 'increment' | 'decrement',
    key: string,
    value: string | number
  ) => {
    if (action === 'increment' && Number(value) < 1000) {
      onUpdateStyle(key, Number(value) + 1);
    } else if (Number(value) > 0) {
      onUpdateStyle(key, Number(value) - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      const newArray = [...templateBlocks];
      reader.onloadend = () => {
        const imageItem: BookleTemplateBlock = findItemById(
          newArray,
          item.id as string
        );
        if (imageItem) {
          imageItem.image = reader.result as string;
          updateBlocks(newArray);
        }

        uploadFile(file, (value) => {
          onUpdateItem('image', value as string);
        });

        onUpdateItem('image', reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.urlInput}>
        <UrlInput
          value={item.url}
          onChange={(value) => onUpdateItem('url', value)}
        />
      </div>
      <input
        type="file"
        accept={'image/*'}
        onChange={handleChange}
        id={`image-${item.id}`}
        className={styles.imageInput}
      />
      <div className={styles.buttonContainer}>
        <label htmlFor={`image-${item.id}`} className={styles.imageContent}>
          <div className={styles.button}>
            Upload Image <ArrowRight className={styles.arrowTop} />
          </div>
        </label>
      </div>
      <div className={styles.customHeightContainer}>
        <div className={styles.toggleContainer}>
          <ChangeHeight className={styles.inputIcon} />
          <div className={styles.title}>Custom Height</div>
          <ToggleSwitch
            isBinary
            checkValue={item.customHeight}
            onToggle={(value) => onUpdateItem('customHeight', value)}
          />
        </div>
        {item.customHeight && (
          <SizeStyleControl
            step={1}
            max={1000}
            min={0}
            value={item.styles?.height as string}
            theme="dark"
            displayArrowsOnHover={false}
            customArrows={
              <CustomArrowsForSizes
                className={styles.arrows}
                onIncrement={() =>
                  changeSize(
                    'increment',
                    'height',
                    (item.styles?.height as number) || 200
                  )
                }
                onDecrement={() =>
                  changeSize(
                    'decrement',
                    'height',
                    (item.styles?.height as number) || 200
                  )
                }
              />
            }
            postfix={<span className={styles.px}>px</span>}
            className={styles.sizeFieldWrapper}
            inputContainerClassName={styles.inputContainerClassName}
            inputClassName={styles.sizeField}
            onChange={(value) => {
              onUpdateStyle('height', Number(value));
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageTab);
