export const GET_ONE_GENERATION_TASK = {
  operationName: 'getOneGenerationTask',
  query: `query getOneGenerationTask(
              $id: String!
            ) {
              getOneGenerationTask(
                id: $id
              ) {
                _id
                data
                layout
                name
                template {
                    _id
                }
                status
              }
            }`,
};
