export const GET_GENERATION_TASKS_QUERY_WITH_PAGINATION = {
  operationName: 'getGenerationTasksWithPagination',
  query: `query getGenerationTasksWithPagination ($skip: Float!, $limit: Float!, $type: String!, $search: String, $sortBy: String, $sortAsc: Boolean) {
          getGenerationTasksWithPagination(skip: $skip, limit: $limit, type: $type, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
          tasks {
              _id
              name
              type
              status
              createdAt
              updatedAt
              thumbnail
          }
          total
        }
      }`,
};
