import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { INode } from '../Nodes/Node';
import BackgroundPattern from 'Assets/icons/nodesBackgroundPattern.svg';
import { INodeAdder } from '../TemplateView/TemplateView';
interface IPros {
  nodes: any; // Object with Keys for string and INode
  nodeAdder: INodeAdder | null;
}

const TemplateEditorCanvas = (props: IPros) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const pixelRatio = window.devicePixelRatio;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          setWidth(width);
          setHeight(height);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        context.resetTransform();
        context.scale(pixelRatio, pixelRatio);
        context.clearRect(0, 0, canvas.width, canvas.height);
        drawConnections(context);
      }
    }
  }, [props.nodes, width, height, props.nodeAdder]);

  const drawConnectionLine = (
    context: CanvasRenderingContext2D,
    input: { x: number; y: number },
    output: { x: number; y: number }
  ) => {
    const radius = 15;
    context.beginPath();
    const xThreshold = 25;
    const yThreshold = 25;
    const yOffset = 40;
    const xOffset = 10;

    //If Input node is on the left.
    if (input.x - xThreshold < output.x) {
      let rc = (output.x - input.x + xThreshold) / (radius * 2);
      rc = Math.min(Math.max(rc, 0), 1);

      context.moveTo(output.x, output.y);
      if (input.y < output.y) {
        context.arc(
          output.x - radius * rc,
          input.y + radius * rc - yOffset,
          radius * rc,
          0 * Math.PI,
          -0.5 * Math.PI,
          true
        );
      } else {
        context.arc(
          output.x - radius * rc,
          input.y - radius * rc - yOffset,
          radius * rc,
          0 * Math.PI,
          0.5 * Math.PI,
          false
        );
      }
      context.arc(
        input.x + radius * rc - xThreshold,
        input.y + radius * rc - yOffset,
        radius * rc,
        -0.5 * Math.PI,
        1.0 * Math.PI,
        true
      );

      context.arc(
        input.x + radius - xThreshold,
        input.y - radius,
        radius,
        -1.0 * Math.PI,
        0.5 * Math.PI,
        true
      );
      context.lineTo(input.x, input.y);
    } else {
      context.moveTo(output.x, output.y);
      if (input.y < output.y) {
        context.arc(
          output.x + radius,
          input.y + radius,
          radius,
          -1.0 * Math.PI,
          -0.5 * Math.PI,
          false
        );
      } else {
        context.arc(
          output.x + radius,
          input.y - radius,
          radius,
          -1.0 * Math.PI,
          0.5 * Math.PI,
          true
        );
      }
      context.lineTo(input.x, input.y);
    }
    context.stroke();
  };

  const drawConnections = (context: CanvasRenderingContext2D) => {
    context.lineWidth = 2;
    context.strokeStyle = '#A0A0A0';

    for (const key in props.nodes) {
      const node = props.nodes[key];
      for (const connection of node.connections) {
        const targetNode = props.nodes[connection];

        const input = { x: targetNode.x, y: targetNode.y + 25 };
        const ouput = { x: node.x + 25, y: node.y + node.height };
        drawConnectionLine(context, input, ouput);
      }
    }

    if (props.nodeAdder) {
      const input = { x: props.nodeAdder.targetX, y: props.nodeAdder.targetY };
      const ouput = { x: props.nodeAdder.sourceX, y: props.nodeAdder.sourceY };
      drawConnectionLine(context, input, ouput);
    }
  };

  const displayWidth = Math.floor(pixelRatio * width);
  const displayHeight = Math.floor(pixelRatio * height);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${BackgroundPattern})`,
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: '100%' }}
        width={displayWidth}
        height={displayHeight}
      />
    </div>
  );
};

export default TemplateEditorCanvas;
