import { truncateString } from 'utils/helpers';
import Dropdown from 'UILib/Dropdown/Dropdown';

import styles from './ContactItem.module.scss';

interface IProps {
  csvColumn: string;
  contactField: string;
  preview: string;
  onChange: (key: string, value: string | boolean) => void;
}

const options = [
  { label: 'Do Not Import', value: 'Do Not Import' },
  { label: 'Full Name', value: 'Full Name' },
  { label: 'First Name', value: 'First Name' },
  { label: 'Last Name', value: 'Last Name' },
  { label: 'Email', value: 'Email' },
  { label: 'Phone', value: 'Phone' },
  { label: 'Country', value: 'Country' },
  { label: 'Address', value: 'Address' },
  { label: 'Tags', value: 'Tags' },
];

const ContactItem = ({
  csvColumn,
  contactField,
  preview,
  onChange,
}: IProps) => {
  return (
    <>
      <div className={styles.csvColumn}>{csvColumn}</div>
      <div className={styles.preview}>{truncateString(preview, 40)}</div>
      <div className={styles.contactField}>
        <Dropdown
          type="stroke"
          label={contactField}
          onChange={(newValue) => onChange('contactField', newValue)}
          value={contactField}
          options={options}
          className={styles.dropdown}
        />
      </div>
    </>
  );
};

export default ContactItem;
